import { Component, Input, OnInit } from '@angular/core';
import { HelpWindowType } from './help-window.model';
import { Store } from '@ngrx/store';
import { startExam } from '../../exam/exam.actions';
import {AppUser} from '../../user/shared/user.models';

@Component({
  selector: 'app-help-window',
  templateUrl: './help-window.component.html',
  styleUrls: ['./help-window.component.scss']
})
export class HelpWindowComponent implements OnInit {
  @Input()
  windowTitle!: string;

  @Input()
  windowType!: HelpWindowType;

  @Input()
  windowText!: string;

  @Input()
  icon!: string;

  @Input()
  buttonText!: string;

  @Input()
  buttonRoute!: string;

  @Input()
  authUser!: AppUser | null;

  get authUserData(): AppUser {
    if (this.authUser?.authUser) {
      return this.authUser?.authUser;
    }

    this.authUser = JSON.parse(sessionStorage.getItem('authUser') as string) as AppUser;
    return this.authUser;
  }

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

  onHelpWindowClickAction(): void {
    this.store.dispatch(startExam({payload: this.authUserData}));
  }

}
