import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Lesson, Section } from '../../admin/admin-lesson/shared/admin-lesson.models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LessonSectionService {
  sectionCollection!: AngularFirestoreCollection<Section[]>;
  lessonSections!: Observable<Section[]>;
  lessonSection!: Observable<Section>;
  lesson!: Lesson | undefined;

  constructor(private afs: AngularFirestore) {
    this.sectionCollection = this.afs.collection<Section[]>('sections');
  }

  getLessonSections(param: string): Observable<Section[]> {
    this.lessonSections = this.afs.collection<Section[]>(
      'sections', ref => ref.where('level', '==', param)
    ).snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Section;
          const id = a.payload.doc.id;
          return {id, ...data};
        }))
      );

    return this.lessonSections;
  }

  getSingleLesson(lessonName: string | undefined, lessons: Lesson[]): Observable<Lesson | undefined> {
    return of(lessons.find(x => x.name === lessonName));
  }
}
