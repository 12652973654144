<div *ngIf="control?.invalid && control?.touched" class="form-validation-message mt-1">
  <p *ngIf="control?.errors?.required" class="text text-danger">
    {{ 'form.validation.required' | translate }}
  </p>

  <p *ngIf="control?.errors?.email" class="text text-danger">
    {{ 'form.validation.email' | translate }}
  </p>

  <p *ngIf="control?.errors?.minlength" class="text text-danger">
    {{ 'form.validation.weakPassword' | translate }}
  </p>

  <p *ngIf="control?.errors?.notMatch === false && !control?.errors?.required" class="text text-danger">
    {{ 'form.validation.passwordDoesntMatch' | translate }}
  </p>
</div>
