import { createAction, props } from '@ngrx/store';
import { Lesson } from '../../admin/admin-lesson/shared/admin-lesson.models';

export const loadLessonSections = createAction(
  '[Lesson Section] Load Lesson Sections',
  props<{payload: string}>()
);

export const loadLessonSectionsSuccess = createAction(
  '[Lesson Section] Load Lesson Sections Success',
  props<{ payload: Lesson[] }>()
);

export const loadLessonSectionsFailure = createAction(
  '[Lesson Section] Load Lesson Sections Failure',
  props<{ error: any }>()
);

/**
 * LOAD SINGLE LESSON
 * ----------------------------------------------------------------------
 */
export const loadSingleLesson = createAction(
  '[Lesson Section] Load Single Lesson',
  props<{payload: string | undefined}>()
);

export const loadSingleLessonSuccess = createAction(
  '[Lesson Section] Load Single Lesson Success',
  props<{payload: Lesson | undefined}>()
);

export const loadSingleLessonFailure = createAction(
  '[Lesson Section] Load Single Lesson Failure',
  props<{ error: any }>()
);
