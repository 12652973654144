import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadAddExamSingleSectionResult,
  loadAddExamSingleSectionResultFailed,
  loadAddExamSingleSectionResultSuccess,
  startExam,
  startExamFailed,
  startExamSuccess
} from './exam.actions';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ExamService } from './exam.service';
import { from, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { loadAuthUser } from '../auth/shared/auth.actions';
import { selectAuthUser } from '../auth/shared/auth.selectors';



@Injectable()
export class ExamEffects {

  /**
   * Start Exam
   * ----------------------------------------------------------------------
   */
  startExam$ = createEffect(() => this.actions$.pipe(
    ofType(startExam),
    mergeMap((action) => {
      return from(this.examService.startExam(action.payload))
        .pipe(
          map(() => startExamSuccess()),
          catchError((error: any) => of(startExamFailed({error})))
        );
    })
  ));


  startExamSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(startExamSuccess),
    tap(() => this.store.dispatch(loadAuthUser()))
  ), {dispatch: false});

  /**
   * Add Exam Single Section Result
   * ----------------------------------------------------------------------
   */
  loadAddExamSingleSectionResult$ = createEffect(() => this.actions$.pipe(
    ofType(loadAddExamSingleSectionResult),
    mergeMap((action) => from(this.examService.addSingleExamResult(action.payload))
      .pipe(
        map(() => loadAddExamSingleSectionResultSuccess()),
        catchError((error: any) => of(loadAddExamSingleSectionResultFailed({error})))
      )
    )
  ));

  loadAddExamSingleSectionResultSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loadAddExamSingleSectionResultSuccess),
    tap(() => this.store.dispatch(loadAuthUser()))
  ), {dispatch: false});

  constructor(private actions$: Actions,
              private examService: ExamService,
              private store: Store) {}

}
