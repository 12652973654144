import { Component, OnInit } from '@angular/core';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
import { authSignOut } from '../../auth/shared/auth.actions';

@Component({
  selector: 'app-admin-aside-nav',
  templateUrl: './admin-aside-nav.component.html',
  styleUrls: ['./admin-aside-nav.component.scss']
})
export class AdminAsideNavComponent implements OnInit {

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
  }

  onSignOut(): void {
    this.store.dispatch(authSignOut());
  }

}
