<aside class="admin-aside card text-white bg-info position-fixed">
  <nav class="d-flex justify-content-center align-items-center h-100">
    <ul class="list-unstyled">
      <li class="admin-aside-nav-link mb-4">
        <i class="bi bi-house p-3"></i>
      </li>
      <li class="admin-aside-nav-link mb-4"
          routerLinkActive="nav-link-active"
          placement="right"
          ngbTooltip="{{ 'tooltips.lessonList' | translate }}">
        <a routerLink="/admin/sections"><i class="bi bi-journals p-3"></i></a>
      </li>
      <li class="admin-aside-nav-link mb-4"
          routerLinkActive="nav-link-active"
          placement="right"
          ngbTooltip="{{ 'tooltips.customerList' | translate }}">
        <a routerLink="users"><i class="bi bi-people p-3"></i></a>
      </li>
      <li class="admin-aside-nav-link mb-4"
          placement="right"
          ngbTooltip="{{ 'tooltips.studentSystem' | translate }}">
        <a routerLink="/student-system"><i class="bi bi-book p-3"></i></a>
      </li>
      <li class="admin-aside-nav-link"
          placement="right"
          ngbTooltip="{{ 'tooltips.exit' | translate }}"
          (click)="onSignOut()">
        <i class="bi bi-box-arrow-left p-3"></i>
      </li>
    </ul>
  </nav>
</aside>
