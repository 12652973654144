import { createReducer, on } from '@ngrx/store';
import { LessonModel, Section } from '../../shared/level.models';
import { loadLevelSectionsSuccess } from '../../shared/level.actions';


export const levelSectionsFeatureKey = 'levelSections';

export interface LevelSectionsState {
  lessons: LessonModel[];
  sections: Section[];
}

export const initialState: LevelSectionsState = {
  lessons: [],
  sections: []
};


export const levelSectionsStateReducer = createReducer(
  initialState,
  on(loadLevelSectionsSuccess,
  (state, action) => ({...state, sections: action.payload})
  )
);

