import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;
import {Exam} from '../../exam/exam.models';

export interface AppUser {
  users?: AppUser[];
  id?: string;
  uid?: string;
  displayName?: string;
  email?: string;
  password?: string;
  emailVerified?: boolean;
  createdAt?: Timestamp;
  levels?: UserLevel[];
  role?: string;
  accountStatus?: string;
  agreeTermsCondition?: boolean;
  authUser?: AppUser;
  exams?: Exam[];
  exam?: any;
  examBeginnerStart?: number;
}

export interface UserLevel {
  name?: string;
  status?: string;
  dataOfDeactivate?: string;
}

export enum UserRole {
  ADMIN = 'AdminLearnEnglish9999991110000555222244999',
  STUDENT = 'Student'
}

export enum AccountStatus {
  ENABLE = 'enable',
  DISABLE = 'disable'
}

export enum Levels {
  BEGINNER = 'Beginner',
  ELEMENTARY = 'Elementary',
  PRE_INTERMEDIATE = 'Pre-intermediate'
}



