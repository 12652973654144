import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromAuth from '../auth/shared/auth.reducer';
import * as fromUser from '../user/shared/user.reducer';
import * as fromLesson from './lesson/lesson.reducer';
import * as fromAdminLesson from '../admin/admin-lesson/shared/admin-lesson.reducer';

import * as levelSections from '../level/lesson/shared/lesson.reducer';
import * as level from '../level/shared/level.reducer';

export interface AppState {
  [fromAuth.authFeatureKey]: fromAuth.AuthState;
  [fromUser.userFeatureKey]: fromUser.UserState;
  [fromLesson.lessonFeatureKey]: fromLesson.LessonState;
  [fromAdminLesson.adminLessonFeatureKey]: fromAdminLesson.AdminLessonState;
  [level.levelFeatureKey]: level.LevelState;
  [levelSections.levelSectionsFeatureKey]: levelSections.LevelSectionsState;
}

export const reducers: ActionReducerMap<AppState> = {
  [fromAuth.authFeatureKey]: fromAuth.authReducer,
  [fromUser.userFeatureKey]: fromUser.usersReducer,
  [fromLesson.lessonFeatureKey]: fromLesson.lessonReducer,
  [fromAdminLesson.adminLessonFeatureKey]: fromAdminLesson.adminLesson,
  [level.levelFeatureKey]: level.levelReducer,
  [levelSections.levelSectionsFeatureKey]: levelSections.levelSectionsStateReducer
};


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
