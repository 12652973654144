import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;

export interface Level {
  name?: string;
  lessons?: Lesson[];
}

export interface Lesson {
  name?: string;
  sections?: Section[];
}

export interface Section {
  id?: string;
  level?: string;
  lesson?: string;
  title?: string;
  editor?: any;
  video?: string;
  audio?: string;
  wordsTitle?: string;
  words?: Word[];
  positionOfQuery?: number;
  dataCreation?: Timestamp;
  sections?: Section[];
  section?: Section;
}

export interface Word {
  iconPath?: string;
  word: string;
  translate: string;
  transcription: string;
  pronounce: string;
}

export enum LevelType {
  BEGINNER = 'Beginner',
  ELEMENTARY = 'Elementary',
  PRE_INTERMEDIATE = 'PreIntermediate'
}

export const mockLevels = [
  { id: 1, name: 'Beginner' },
  { id: 2, name: 'Elementary' },
  { id: 2, name: 'Pre-intermediate' }
];

export const mockLessons = [
  { id: 1, name: '1A' },
  { id: 2, name: '1B' },
  { id: 3, name: '1C' },
  { id: 4, name: '1D' },
  { id: 5, name: '1E' },
  { id: 6, name: '2A' },
  { id: 7, name: '2B' },
  { id: 8, name: '2C' },
  { id: 9, name: '2D' },
  { id: 10, name: '2E' },
  { id: 11, name: '3A' },
  { id: 12, name: '3B' },
  { id: 13, name: '3C' },
  { id: 14, name: '3D' },
  { id: 15, name: '3E' },
  { id: 16, name: '4A' },
  { id: 17, name: '4B' },
  { id: 18, name: '4C' },
  { id: 19, name: '4D' },
  { id: 20, name: '4E' },
  { id: 21, name: '5A' },
  { id: 22, name: '5B' },
  { id: 23, name: '5C' },
  { id: 24, name: '5D' },
  { id: 25, name: '5E' },
  { id: 26, name: '6A' },
  { id: 27, name: '6B' },
  { id: 28, name: '6C' },
  { id: 29, name: '6D' },
  { id: 30, name: '6E' },
  { id: 31, name: '7A' },
  { id: 32, name: '7B' },
  { id: 33, name: '7C' },
  { id: 34, name: '7D' },
  { id: 35, name: '7E' },
  { id: 36, name: '8A' },
  { id: 37, name: '8B' },
  { id: 38, name: '8C' },
  { id: 39, name: '8D' },
  { id: 40, name: '8E' },
  { id: 41, name: '9A' },
  { id: 42, name: '9B' },
  { id: 43, name: '9C' },
  { id: 44, name: '9D' },
  { id: 45, name: '9F' },
  { id: 46, name: 'Video 1' },
  { id: 47, name: 'Video 2' },
  { id: 48, name: 'Video 3' },
  { id: 49, name: 'Video 4' },
  { id: 50, name: 'Video 5' },
  { id: 51, name: 'Video 6' },
  { id: 52, name: 'Video 7' },
  { id: 53, name: 'Video 8' },
  { id: 54, name: 'Book 1' },
  { id: 55, name: 'Book 2' },
  { id: 56, name: 'Book 3' },
  { id: 57, name: 'Book 4' },
  { id: 58, name: 'Book 5' },
  { id: 59, name: 'Story 1' },
  { id: 60, name: 'Story 2' },
  { id: 61, name: 'Story 3' },
];

export const mockPointOfQuery = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
];

export enum ActivatedFormTypes {
  ACTIVATED_EDITOR = 'activatedEditor',
  ACTIVATED_VIDEO = 'activatedVideo',
  ACTIVATED_AUDIO = 'activatedAudio',
  ACTIVATED_ICON_PATH = 'activatedIconPath',
}

