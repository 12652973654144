import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import {
  authSignOut,
  authSignOutFailed,
  authSignOutSuccess,
  loadAuthSignIn,
  loadAuthSignInFailed,
  loadAuthSignInSuccess,
  loadAuthSignUp,
  loadAuthSignUpFailed,
  loadAuthSignUpSuccess,
  loadAuthUser,
  loadAuthUserFailure,
  loadAuthUserSuccess,
  passwordResetLink,
  passwordResetLinkFailed,
  passwordResetLinkSuccess
} from './auth.actions';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {AppUser} from '../../user/shared/user.models';
import {AppState} from '../../reducers';
import {Store} from '@ngrx/store';



@Injectable()
export class AuthEffects {
  /**
   * Sign Up
   * --------------------------------------------- *
   */
  loadAuthSignUp$ = createEffect(() => this.actions$.pipe(
    ofType(loadAuthSignUp),
    mergeMap((action) => from(this.authService.signUp(action.payload))
      .pipe(
        map((user: any) => loadAuthSignUpSuccess(user)),
        catchError((error: any) => of(loadAuthSignUpFailed({error})))
      )
    )
  ));

  /**
   * Sign In
   * --------------------------------------------- *
   */
  loadAuthSignIn$ = createEffect(() => this.actions$.pipe(
    ofType(loadAuthSignIn),
    mergeMap((action) => from(this.authService.signIn(action.payload))
      .pipe(
        map((user) => loadAuthSignInSuccess({payload: user})),
        catchError((error: any) => of(loadAuthSignInFailed({error})))
      )
    )
  ));

  /**
   * Sign Out
   * --------------------------------------------- *
   */
  authSignOut$ = createEffect(() => this.actions$.pipe(
    ofType(authSignOut),
    mergeMap(() => {
      return from(this.authService.signOut())
        .pipe(
          map(() => authSignOutSuccess()),
          catchError((error) => of(authSignOutFailed({error})))
        );
    })
  ));

  /**
   * Password Reset
   * --------------------------------------------- *
   */
  passwordResetLink$ = createEffect(() => this.actions$.pipe(
    ofType(passwordResetLink),
    mergeMap(action => {
      return from(this.authService.passwordResetSendLink(action.email)).pipe(
        map(() => passwordResetLinkSuccess()),
        catchError((error) => of(passwordResetLinkFailed({error})))
      );
    })
  ));

  /**
   * Load Auth User Data
   * --------------------------------------------- *
   */
  loadAuthUser$ = createEffect(() => this.actions$.pipe(
    ofType(loadAuthUser),
    mergeMap(() => this.authService.getAuthUser()
      .pipe(
        map((payload: AppUser) => {
          return loadAuthUserSuccess({payload});
        }),
        catchError((error) => of(loadAuthUserFailure(error)))
      )
    )
  ));

  /**
   * Notifications
   * --------------------------------------------- *
   */
  loadAuthNotification$ = createEffect(() => this.actions$.pipe(
    ofType(
      loadAuthSignUpSuccess,
      loadAuthSignUpFailed,
      loadAuthSignInSuccess,
      loadAuthSignInFailed,
      passwordResetLinkSuccess,
      authSignOutSuccess,
      authSignOutFailed
    ),
    tap((action) => {
      switch (action.type) {
        case loadAuthSignUpSuccess.type:
          this.toastr.success(this.translate.instant('notifications.signUpSuccess'));
          return this.router.navigate(['/auth/sign-in']).then();

        case loadAuthSignUpFailed.type:
          if (action.error.code === 'auth/email-already-in-use') {
            return this.toastr.error(this.translate.instant('notifications.emailAlreadyUsed'));
          }
          return null;

        case loadAuthSignInSuccess.type:
          this.router.navigate(['/student-system']).then();
          return this.toastr.success(this.translate.instant('notifications.signInSuccess'));

        case loadAuthSignInFailed.type:
          if (action.error.code === 'auth/user-not-found' || action.error.code === 'auth/wrong-password') {
            return this.toastr.error(this.translate.instant('notifications.userNotFound'));
          }

          if (action.error.code === 'auth/user-disabled') {
            return this.toastr.error(this.translate.instant('notifications.userDisabled'));
          }
          return null;

        case passwordResetLinkSuccess.type:
          this.router.navigate(['/auth/sign-in']).then();
          return this.toastr.success(this.translate.instant('notifications.sentPasswordResetLinkSuccess'));

        case authSignOutSuccess.type:
          this.router.navigate(['/']).then();
          return this.toastr.success(this.translate.instant('notifications.signOutSuccess'));

        case authSignOutFailed.type:
          return this.toastr.error(this.translate.instant('notifications.signOutFailed'));

        default:
          return null;

      }
    })
  ), {dispatch: false});

  constructor(private actions$: Actions,
              private authService: AuthService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private router: Router) {}

}
