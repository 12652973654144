import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicLayoutComponent } from './layouts/public/public-layout/public-layout.component';
import { AdminLayoutComponent } from './admin/admin-layout/admin-layout.component';
import { PrivateLayoutComponent } from './layouts/private/private-layout/private-layout.component';
import { AuthAdminGuard } from './auth/shared/auth-admin.guard';
import { AuthGuard } from './auth/shared/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthAdminGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'student-system',
    component: PrivateLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./lesson/lesson.module').then(m => m.LessonModule)
  },
  {
    path: 'new-student-system',
    component: PrivateLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./level/level.module').then(m => m.LevelModule)
  },
  {
    path: 'exam',
    component: PrivateLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./exam/exam.module').then(m => m.ExamModule)
  },
  { path: '**', redirectTo: '' },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
