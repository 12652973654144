import { createReducer, on } from '@ngrx/store';
import { loadAdminLessonSectionsSuccess, loadAdminLessonSectionSuccess } from './admin-lesson.actions';
import { Section } from './admin-lesson.models';


export const adminLessonFeatureKey = 'adminLesson';

export interface AdminLessonState {
  sections: Section[];
  section: Section;
}

export const adminLessonInitialState: AdminLessonState = {
  sections: [],
  section: {} as Section
};

export const adminLesson = createReducer(
  adminLessonInitialState,
  on(
    loadAdminLessonSectionsSuccess,
    (state, action) => ({...state, sections: action.payload})
  ),
  on(
    loadAdminLessonSectionSuccess,
    (state, action) => ({...state, section: action.payload})
  )
);
