import { createAction, props } from '@ngrx/store';
import { AppUser } from '../../user/shared/user.models';
import firebase from 'firebase';
import UserCredential = firebase.auth.UserCredential;

export const loadAuthUser = createAction(
  '[Auth] Load Auths'
);

export const loadAuthUserSuccess = createAction(
  '[Auth] Load Auths Success',
  props<{ payload: AppUser }>()
);

export const loadAuthUserFailure = createAction(
  '[Auth] Load Auths Failure',
  props<{ error: any }>()
);

/**
 * Sign Up
 * --------------------------------------------- *
 */
export const loadAuthSignUp = createAction(
  '[Auth] Load Auth Sign Up',
  props<{payload: AppUser}>()
);

export const loadAuthSignUpSuccess = createAction(
  '[Auth] Load Auth Sign Up Success',
  props<{payload: UserCredential}>()
);

export const loadAuthSignUpFailed = createAction(
  '[Auth] Load Auth Sign Up Failed',
  props<{ error: any }>()
);

/**
 * Sign In
 * --------------------------------------------- *
 */
export const loadAuthSignIn = createAction(
  '[Auth] Load Auth Sign In',
  props<{payload: AppUser}>()
);

export const loadAuthSignInSuccess = createAction(
  '[Auth] Load Auth Sign In Success',
  props<{ payload: UserCredential }>()
);

export const loadAuthSignInFailed = createAction(
  '[Auth] Load Auth Sign In Failed',
  props<{ error: any }>()
);

/**
 * SIGN OUT
 * ----------------------------------------------------------------------
 */
export const authSignOut = createAction(
  '[Auth] Auth Sign Out'
);

export const authSignOutSuccess = createAction(
  '[Auth] Auth Sign Out Success'
);

export const authSignOutFailed = createAction(
  '[Auth] Auth Sign Out Failed',
  props<{error?: {code: string}}>()
);

/**
 * Password Reset
 * --------------------------------------------- *
 */
export const passwordResetLink = createAction(
  '[Auth] Password Reset Link',
  props<{email: string}>()
);

export const passwordResetLinkSuccess = createAction(
  '[Auth] Password Reset Link Success'
);

export const passwordResetLinkFailed = createAction(
  '[Auth] Password Reset Link Failed',
  props<{error?: {code: string}}>()
);
