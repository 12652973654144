import { createReducer, on } from '@ngrx/store';
import { AppUser } from './user.models';
import { loadUsersSuccess } from './user.actions';


export const userFeatureKey = 'user';

export interface UserState {
  users: AppUser[];
}

export const initialState: UserState = {
  users: [] as AppUser[]
};


export const usersReducer = createReducer(
  initialState,
  on(
    loadUsersSuccess,
    (state, action) => ({...state, users: action.payload})
  )
);
