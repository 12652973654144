import { createReducer, createSelector, on } from '@ngrx/store';
import { Lesson } from '../../admin/admin-lesson/shared/admin-lesson.models';
import { loadLessonSectionsSuccess, loadSingleLessonSuccess } from '../../lesson/shared/lesson-section.actions';
import { AppState } from '../index';


export const lessonFeatureKey = 'lesson';

export interface LessonState {
  lessons: Lesson[];
  lesson: Lesson | undefined;
}

export const initialState: LessonState = {
  lessons: [],
  lesson: {} as Lesson
};

export const lessonReducer = createReducer(
  initialState,
  on(loadLessonSectionsSuccess,
    (state, action) => {
      return({
        ...state,
        lessons: action.payload
      });
    }),
  on(loadSingleLessonSuccess,
    (state, action) => {
      return ({
        ...state,
        lesson: action.payload
      });
    })
);

/*** Selectors **/
export const selectLessonFeature = (state: AppState) => state[lessonFeatureKey];

export const selectLessonsSelector = createSelector(
  selectLessonFeature,
  (state: LessonState) => state?.lessons
);

export const selectSingleLessonSelector = createSelector(
  selectLessonFeature,
  (state: LessonState) => {
    let lesson = {} as Lesson;
    if (state?.lesson) {
      lesson = JSON.parse(JSON.stringify(state?.lesson));

      // @ts-ignore
      lesson?.sections?.sort((a, b) => a.positionOfQuery - b.positionOfQuery);
    }

    return lesson;
  }
);
