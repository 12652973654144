import { createAction, props } from '@ngrx/store';
import { Exam } from './exam.models';
import { AppUser } from '../user/shared/user.models';

export const startExam = createAction(
  '[Exam] Start Exam',
  props<{payload: AppUser}>()
);

export const startExamSuccess = createAction(
  '[Exam] Start Exam Success'
);

export const startExamFailed = createAction(
  '[Exam] Start Exam Failure',
  props<{ error: any }>()
);



export const loadAddExamSingleSectionResult = createAction(
  '[Exam] Load Add Exam Single Section Result',
  props<{payload: Exam}>()
);

export const loadAddExamSingleSectionResultSuccess = createAction(
  '[Exam] Load Add Exam Single Section Result Success'
);

export const loadAddExamSingleSectionResultFailed = createAction(
  '[Exam] Load Add Exam Single Section Result Failed',
  props<{ error: any }>()
);
