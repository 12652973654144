import { Component, OnInit } from '@angular/core';
import { loadAdminLessonSections } from '../admin-lesson/shared/admin-lesson.actions';
import { Store } from '@ngrx/store';
import { loadAuthUser } from '../../auth/shared/auth.actions';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(loadAuthUser());
    this.store.dispatch(loadAdminLessonSections());
  }

}
