import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicLayoutComponent } from './public/public-layout/public-layout.component';
import { SharedModule } from '../shared/shared.module';
import { PublicHeaderComponent } from './public/public-header/public-header.component';
import { PrivateHeaderComponent } from './private/private-header/private-header.component';
import { PrivateLayoutComponent } from './private/private-layout/private-layout.component';
import { EffectsModule } from '@ngrx/effects';
import { LessonSectionEffects } from '../lesson/shared/lesson-section.effects';
import { AuthEffects } from '../auth/shared/auth.effects';

@NgModule({
  declarations: [
    PublicLayoutComponent,
    PublicHeaderComponent,
    PrivateHeaderComponent,
    PrivateLayoutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([LessonSectionEffects, AuthEffects])
  ],
  exports: [
  ]
})
export class LayoutsModule { }
