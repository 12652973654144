import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadLessonSections,
  loadLessonSectionsFailure,
  loadLessonSectionsSuccess, loadSingleLesson, loadSingleLessonFailure, loadSingleLessonSuccess
} from './lesson-section.actions';
import { mergeMap, map, catchError, withLatestFrom} from 'rxjs/operators';
import { of, from } from 'rxjs';
import { LessonSectionService } from './lesson-section.service';
import { Lesson, Section } from '../../admin/admin-lesson/shared/admin-lesson.models';
import { selectLessonsSelector } from '../../reducers/lesson/lesson.reducer';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';


@Injectable()
export class LessonSectionEffects {

  loadLessonSections$ = createEffect(() => this.actions$.pipe(
    ofType(loadLessonSections),
    mergeMap((action) => this.lessonSectionService.getLessonSections(action.payload)
      .pipe(
        map((data: Section[]) => {

          // TODO all logic to go to service
          const payload = Array.from(new Set(data.map(x => x.lesson)))
            .map(lesson => {
              return {
                name: lesson,
                sections: data.map(y => y.lesson === lesson && y).filter(Boolean)
              };
            }) as Lesson[];

          // @ts-ignore
          payload?.sort((a, b) => (a.name > b.name) ? 1 : -1);

          return loadLessonSectionsSuccess({payload});
        }),
        catchError((error: string) => of(loadLessonSectionsFailure({error})))
      )
    )
  ));

  loadSingleLesson$ = createEffect(() => this.actions$.pipe(
    ofType(loadSingleLesson),
    withLatestFrom(this.store.select(selectLessonsSelector)),
    mergeMap(([action, lessons]) => from(this.lessonSectionService.getSingleLesson(action.payload, lessons))
      .pipe(
        map((data) => loadSingleLessonSuccess({payload: data})),
        catchError((error: string) => of(loadSingleLessonFailure({error})))
      )
    )
  ));

  constructor(private actions$: Actions,
              private lessonSectionService: LessonSectionService,
              private store: Store<AppState>) {}

}
