import { createAction, props } from '@ngrx/store';
import { Section } from './level.models';

export const loadLevel = createAction(
  '[Level] Load Level',
  props<{payload: string}>()
);

export const loadLevelSuccess = createAction(
  '[Level] Load level success',
  props<{payload: string}>()
);

export const loadLevelSections = createAction(
  '[Level] Load level Sections',
  props<{payload: string | null}>()
);

export const loadLevelSectionsSuccess = createAction(
  '[Level] Load level Sections Success',
  props<{payload: Section[]}>()
);

export const loadLevelSectionsFailed = createAction(
  '[Level] Load level Sections Failed',
  props<{ error: any }>()
);
