<div class="card">
  <div class="card-body">
    <h2><small>{{ windowTitle }}</small></h2>
    <span class="help-icon d-block position-absolute">
    <i class="bi" [ngClass]="icon"></i>
  </span>
    <hr>
    <p [innerHTML]="windowText"></p>

    <button *ngIf="buttonRoute"
            class="btn btn-info"
            (click)="onHelpWindowClickAction()"
            routerLink="{{buttonRoute}}">
      {{ buttonText }}
    </button>
  </div>
</div>

