<div class="row justify-content-center">
  <div class="col-6 col-md-4 col-lg-3 col-xl-3">
    <header class="public-header text-center position-relative">
      <a href="javascript: void(0)" routerLink="'/'">
        <img class="logo w-100" src="assets/images/logo/learn-english-logo.png" width="220" alt="">
      </a>
    </header>
  </div>
</div>




