<nav class="navbar navbar-light bg-white position-relative">
  <div class="fix-skew"></div>
  <div class="d-flex">
    <div class="private-nav-level"
         routerLinkActive="private-nav-level-active"
         [ngbTooltip]="!userHasBeginner ? 'Нямате достъп до това ниво на обучение' : 'Вход в ниво Beginner'"
         placement="bottom">
      <a *ngIf="userHasBeginner; else userHasNotBeginner" class="private-nav-level-link d-inline-block"
         href="javascript: void(0)"
         routerLinkActive="private-nav-level-active"
         [routerLink]="['/', 'new-student-system', 'level', 'beginner']"
         (click)="onChooseLevel('Beginner')">
        {{ 'global.beginner' | translate }}
      </a>

      <ng-template #userHasNotBeginner>
        <a class="private-nav-level-link d-inline-block"
           href="javascript: void(0)">
          {{ 'global.beginner' | translate }}
        </a>
      </ng-template>
    </div>

    <div class="private-nav-level"
         routerLinkActive="private-nav-level-active"
         [ngbTooltip]="!userHasElementary ? 'Нямате достъп до това ниво на обучение' : 'Вход в ниво Elementary'"
         placement="bottom">
      <a *ngIf="userHasElementary; else userHasNotElementary"
         class="private-nav-level-link d-inline-block"
         href="javascript: void(0)"
         [routerLink]="['/', 'new-student-system', 'level', 'elementary']"
         routerLinkActive="private-nav-level-active"
         (click)="onChooseLevel('Elementary')">
        {{ 'global.elementary' | translate }}
      </a>

      <ng-template #userHasNotElementary>
        <a class="private-nav-level-link d-inline-block"
           href="javascript: void(0)">
          {{ 'global.elementary' | translate }}
        </a>
      </ng-template>
    </div>

    <div class="private-nav-level"
         routerLinkActive="private-nav-level-active"
         [ngbTooltip]="!userHasPreIntermediate ? 'Нямате достъп до това ниво на обучение' : 'Вход в ниво Pre-intermediate'"
         placement="bottom">
      <a *ngIf="userHasPreIntermediate; else userHasNotPreIntermediate"
         class="private-nav-level-link d-inline-block"
         href="javascript: void(0)"
         [routerLink]="['/', 'new-student-system', 'level', 'pre-intermediate']"
         routerLinkActive="private-nav-level-active"
         (click)="onChooseLevel('Pre-intermediate')">
        {{ 'global.preIntermediate' | translate }}
      </a>

      <ng-template #userHasNotPreIntermediate>
        <a class="private-nav-level-link d-inline-block"
           href="javascript: void(0)">
          {{ 'global.preIntermediate' | translate }}
        </a>
      </ng-template>
    </div>
  </div>

  <div class="private-user-link"
       ngbDropdown>
    <a class="private-nav-link d-flex align-items-center"
       href="javascript: void(0)"
       id="userProfileDropdown"
       ngbDropdownToggle
       placement="bottom"
       ngbTooltip="{{ 'tooltips.userProfileMenu' | translate }}">
      <i class="bi bi-person-circle"></i>
      <span class="ml-2">{{ authUserName }}</span>
    </a>

    <div class="private-user-link-dropdown" ngbDropdownMenu x-placement="bottom-right" aria-labelledby="userProfileDropdown">
      <button ngbDropdownItem routerLink="/student-system">
        {{ 'btn.home' | translate }}
      </button>
      <button *ngIf="isAdmin" ngbDropdownItem routerLink="/admin">
        {{ 'studentSystem.goToAdmin' | translate }}
      </button>
      <button ngbDropdownItem (click)="onSignOut()">
        {{ 'btn.signOut' | translate }}
      </button>
    </div>
  </div>

</nav>

