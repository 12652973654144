import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAuthUser } from '../../../auth/shared/auth.selectors';
import { loadAuthUser } from '../../../auth/shared/auth.actions';

@Component({
  selector: 'app-private-layout',
  templateUrl: './private-layout.component.html',
  styleUrls: ['./private-layout.component.scss']
})
export class PrivateLayoutComponent implements OnInit {
  authUser$ = this.store.select(selectAuthUser);

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(loadAuthUser());
  }

}
