import { createAction, props } from '@ngrx/store';
import { Section } from './admin-lesson.models';

export const loadAdminLessonSections = createAction(
  '[AdminLesson] Load Admin Lesson Sections'
);

export const loadAdminLessonSectionsSuccess = createAction(
  '[AdminLesson] Load Admin Lesson Sections Success',
  props<{ payload: Section[] }>()
);

export const loadAdminLessonSectionsFailure = createAction(
  '[AdminLesson] Load Admin Lesson Sections Failure',
  props<{ error: any }>()
);

/**
 * LESSON SECTION GET BY ID
 * ----------------------------------------------------------------------
 */
export const loadAdminLessonSection = createAction(
  '[AdminLesson] Load Admin Lesson Section',
  props<{ payload: string }>()
);

export const loadAdminLessonSectionSuccess = createAction(
  '[AdminLesson] Load Admin Lesson Section Success',
  props<{ payload: Section }>()
);

export const loadAdminLessonSectionFailure = createAction(
  '[AdminLesson] Load Admin Lesson Section Failure',
  props<{ error: any }>()
);


/**
 * LESSON SECTION CREATE
 * ----------------------------------------------------------------------
 */
export const loadAdminLessonSectionCreate = createAction(
  '[AdminLesson] Load Admin Lesson Section Create',
  props<{payload: Section}>()
);

export const loadAdminLessonSectionCreateSuccess = createAction(
  '[AdminLesson] Load Admin Lesson Section Create Success',
  props<{payload: Section}>()
);

export const loadAdminLessonSectionCreateFailure = createAction(
  '[AdminLesson] Load Admin Lesson Section Create Failure',
  props<{error?: {code: string}}>()
);

/**
 * LESSON SECTION UPDATE
 * ----------------------------------------------------------------------
 */
export const loadAdminLessonSectionUpdate = createAction(
  '[AdminLesson] Load Admin Lesson Section Update',
  props<{payload: Section}>()
);

export const loadAdminLessonSectionUpdateSuccess = createAction(
  '[AdminLesson] Load Admin Lesson Section Update Success',
  props<{payload: Section}>()
);

export const loadAdminLessonSectionUpdateFailure = createAction(
  '[AdminLesson] Load Admin Lesson Section Update Failure',
  props<{error?: {code: string}}>()
);


/**
 * LESSON SEARCH BY PARAMS
 * ----------------------------------------------------------------------
 */
export const loadAdminLessonSearch = createAction(
  '[AdminLesson] Load Admin Lesson Search',
  props<{payload: Section}>()
);
