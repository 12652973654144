import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormValidationMessageComponent } from './form-validation-message/form-validation-message.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HelpWindowComponent } from './help-window/help-window.component';
import { SaveHtmlPipe } from './save-html/save-html.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { PromotionsComponent } from './promotions/promotions.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { ExamEffects } from '../exam/exam.effects';

@NgModule({
  declarations: [
    FormValidationMessageComponent,
    HelpWindowComponent,
    SaveHtmlPipe,
    PromotionsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      extend: true
    }),
    NgbModule,
    RouterModule,
    ReactiveFormsModule,
    EffectsModule.forFeature([ExamEffects])
  ],
  exports: [
    RouterModule,
    TranslateModule,
    HelpWindowComponent,
    FormValidationMessageComponent,
    NgbModule,
    NgSelectModule,
    SaveHtmlPipe,
    PromotionsComponent
  ]
})
export class SharedModule { }
