import { createReducer, on } from '@ngrx/store';
import { Level } from '../../admin/admin-lesson/shared/admin-lesson.models';
import { loadLevelSuccess } from './level.actions';


export const levelFeatureKey = 'level';

export interface LevelState {
  level: Level;
}

export const initialLevelState: LevelState = {
  level: {} as Level
};


export const levelReducer = createReducer(
  initialLevelState,
  on(loadLevelSuccess,
    (state, action) => (
      {...state,
        level: {
          name: action.payload
        }
      }
    )
  )
);

