<div class="alert alert-primary mb-5" role="alert">
  <i class="bi bi-exclamation-diamond-fill"></i>
  ПРОМОЦИЯ: Вземете 3 нива с промоционална цена от 16лв, предоставена от нашите партньори от Грабо
  <hr>
 Линк към офертата: <a href="https://grabo.bg/onlain-angliiski-0b24n" target="_blank">https://grabo.bg/onlain-angliiski-0b24n</a>
  <ul class="mt-3">
    <li> След закупуване на ваучер, изпратете номера и секретния код на email: info@learnenglish.bg</li>
    <li>Акаунтът ви ще се активира автоматично до 24 часа преди датата на започване на курса!</li>
  </ul>
</div>
