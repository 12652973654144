import { createReducer, on } from '@ngrx/store';
import { AppUser } from '../../user/shared/user.models';
import { loadAuthUserSuccess } from './auth.actions';


export const authFeatureKey = 'auth';

export interface AuthState {
  authUser: AppUser;
}

export const initialState: AuthState = {
  authUser: {} as AppUser
};


export const authReducer = createReducer(
  initialState,
  on(loadAuthUserSuccess,
    (state, action) => ({...state, authUser: action.payload})
  )
);

