import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/shared/auth.service';
import { authSignOut } from '../../../auth/shared/auth.actions';
import { AppState } from '../../../reducers';
import { Store } from '@ngrx/store';
import { AppUser, Levels } from '../../../user/shared/user.models';
import { LevelType} from '../../../admin/admin-lesson/shared/admin-lesson.models';
import { adminUserLevelActiveHelper } from '../../../admin/admin-user/shared/admin-user-level-active.helper';
import {loadLevel, loadLevelSections} from '../../../level/shared/level.actions';
import {Router} from '@angular/router';

@Component({
  selector: 'app-private-header',
  templateUrl: './private-header.component.html',
  styleUrls: ['./private-header.component.scss']
})
export class PrivateHeaderComponent implements OnInit {
  @Input()
  authUser!: any;

  levelType = LevelType;

  get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  get userHasBeginner(): boolean {
    return adminUserLevelActiveHelper(Levels?.BEGINNER);
  }

  get userHasElementary(): boolean {
    return adminUserLevelActiveHelper(Levels?.ELEMENTARY);
  }

  get userHasPreIntermediate(): boolean {
    return adminUserLevelActiveHelper(Levels?.PRE_INTERMEDIATE);
  }

  get authUserName(): string | undefined {
    if (this.authUser?.displayName) {
      return this.authUser?.displayName;
    } else {
      const authUser = JSON.parse(sessionStorage.getItem('authUser') as string) as AppUser;
      return authUser?.displayName;
    }
  }

  constructor(private authService: AuthService,
              private store: Store<AppState>) { }

  ngOnInit(): void {
  }

  onChooseLevel(level: string): void {
    this.store.dispatch(loadLevel({payload: level}));
    this.store.dispatch(loadLevelSections({payload: level}));
    sessionStorage.setItem('level', level);
  }

  onSignOut(): void {
    setTimeout(() => this.store.dispatch(authSignOut()), 500);
  }
}
