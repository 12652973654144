import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AppUser } from '../user/shared/user.models';
import { Exam } from './exam.models';
import firebase from 'firebase';
import FieldValue = firebase.firestore.FieldValue;
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class ExamService {
  userCollection!: AngularFirestoreCollection<AppUser>;
  authUser!: string | null;

  constructor( private afs: AngularFirestore, private afAuth: AngularFireAuth) {
    this.userCollection = this.afs.collection<AppUser>('users');
    this.authUser = sessionStorage.getItem('autUser');
  }

  startExam(authUser: AppUser): Promise<void> {
    const count = authUser?.examBeginnerStart ? authUser?.examBeginnerStart : 0;
    const user = {
      uid: authUser?.uid,
      // @ts-ignore
      examBeginnerStart: count + 1
    } as AppUser;


    // @ts-ignore
    return this.userCollection.doc(user?.uid).update(user).then({
    })
    .catch();
  }

  addSingleExamResult(payload: Exam): Promise<void> {
    const user = {
      uid: payload?.uid,
    } as AppUser;

    // @ts-ignore
    return this.userCollection.doc(user?.uid).update({exams: FieldValue.arrayUnion({
      level: payload?.level,
      type: payload?.type,
      result: payload?.result
    })});
  }
}
