<div class="container">
  <app-public-header></app-public-header>

  <section class="position-relative">
    <div class="image-wrap position-absolute row justify-content-center">
      <div class="col-12 col-md-12 col-lg-8 col-xl-7">
        <img class="woman-img w-100" src="assets/images/public-layout/learn-english-woman.jpeg" alt="">
      </div>
    </div>

    <div class="actions position-relative">
      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-6 col-xl-5">
          <div class="card sign-card">
            <div class="card-body">
              <div class="p-3">
                <button class="btn btn-primary btn-block mb-4" routerLink="/auth/sign-in">
                  {{ 'btn.signIn' | translate }}
                </button>
                <button class="btn btn-info btn-block" routerLink="/auth/sign-up">
                  {{ 'btn.signUp' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <footer class="public-footer text-center pb-4">
    <p><i class="bi bi-envelope"></i> info@learnenglish.bg</p>
    <p>2021 © Всички права запазени! <a href="learnenglish.bg">learnenglish.bg</a> Reserved LTD</p>
  </footer>
  <router-outlet></router-outlet>
</div>

