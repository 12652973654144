import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';
import { AccountStatus, AppUser, UserLevel, UserRole } from '../../user/shared/user.models';
import User = firebase.User;
import UserCredential = firebase.auth.UserCredential;
import auth = firebase.auth;
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import Timestamp = firebase.firestore.Timestamp;
import { Observable } from 'rxjs';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  usersCollection!: AngularFirestoreCollection<AppUser>;
  authUser!: Observable<AppUser>;
  user!: User;
  userUid!: string;
  currentUser!: firebase.User | null | undefined;

  get isAdmin(): boolean {
    // @ts-ignore
    const authUser = JSON.parse(sessionStorage.getItem('authUser'));
    return authUser?.role === UserRole.ADMIN;
  }

  get userStatusActive(): boolean {
    // @ts-ignore
    const authUser = JSON.parse(sessionStorage.getItem('authUser'));

    return authUser?.levels?.find((x: UserLevel) => x?.status === AccountStatus?.ENABLE);
  }

  constructor(private afAuth: AngularFireAuth,
              public ngZone: NgZone,
              public afs: AngularFirestore) {
    this.usersCollection = this.afs.collection<AppUser>('users');

    this.afAuth.authState.subscribe(
      (user) => {
        if (user) {
          this.userUid = user?.uid;

          this.afs.firestore.doc(`users/${this.userUid }`).get().then(
            (appUser) => {
              sessionStorage.setItem('authUser', JSON.stringify(appUser.data()));
            }
          ).catch((error) => {
            console.log('Error getting document:', error);
          });
        } else {
          sessionStorage.clear();
        }
      }
    );
  }

  signUp(payload: AppUser): Promise<void> {
    const email = payload.email;
    const password = payload.password;

    return this.afAuth.createUserWithEmailAndPassword(email as string, password as string)
      .then(() => {
        this.currentUser = firebase?.auth()?.currentUser;
        // @ts-ignore
        this.currentUser.updateProfile({
          displayName: payload.displayName
        }).then(() => {
          this.afs.collection('users').doc(this.currentUser?.uid).set({
            uid: this.currentUser?.uid,
            email: this.currentUser?.email,
            displayName: this.currentUser?.displayName,
            createdAt: Timestamp.now(),
            levels: [],
            role: UserRole.STUDENT,
            accountStatus: AccountStatus.DISABLE,
            agreeTermsCondition: true
          }).then(() => {
          });
        });
      }
    );
  }

  signIn(payload: AppUser): Promise <UserCredential> {
    const email = payload.email;
    const password = payload.password;

    // @ts-ignore
    return this.afAuth.signInWithEmailAndPassword(email as string, password as string).then(() => {
      this.setPersistenceUserData().then();

      this.afAuth.authState.subscribe(
        (user) => {
          if (user) {
            this.userUid = user?.uid;

            this.afs.firestore.doc(`users/${this.userUid }`).get().then(
              (appUser) => {
                this.deactivateAuthUser(appUser.data());

                sessionStorage.setItem('authUser', JSON.stringify(appUser.data()));
              }
            ).catch((error) => {
              console.log('Error getting document:', error);
            });
          } else {
            sessionStorage.clear();
          }
        }
      );
    });
  }

  setPersistenceUserData(): ReturnType<firebase.auth.Auth['setPersistence']> {
    return this.afAuth.setPersistence(auth.Auth.Persistence.SESSION);
  }

  signOut(): Promise<void> {
    // return this.afAuth.setPersistence(auth.Auth.Persistence.NONE).then(() => {
      return this.afAuth.signOut().then(() => {
        sessionStorage.clear();
      }).catch();
      // }).catch();
  }

  passwordResetSendLink(email: string): ReturnType<firebase.auth.Auth['sendPasswordResetEmail']> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  /**
   * Get Auth User
   * --------------------------------------------- *
   */
  getAuthUser(): Observable<AppUser> {
    this.authUser = this.usersCollection.doc(this.userUid).valueChanges() as Observable<AppUser>;

    return this.authUser;
  }

  /**
   * Deactivate status
   * --------------------------------------------- *
   */
  deactivateAuthUser(authUser: AppUser | undefined): void {
    const dateNow = moment().format('YYYY-MM-DD');

    authUser?.levels?.forEach((level, index) => {
      if (level?.status === AccountStatus.ENABLE) {
        const dateDeactivation = level?.dataOfDeactivate;
        const checkStatus = moment(dateDeactivation).isSameOrBefore(dateNow);

        if (checkStatus) {
          // @ts-ignore
          authUser?.levels[index].status = AccountStatus.DISABLE;
          this.usersCollection.doc(authUser?.uid).update(authUser).then(() => {
          });
        }
      }
    });
  }
}
