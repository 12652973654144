import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppUser } from '../../user/shared/user.models';

const stateSelector = createFeatureSelector<AppUser>('auth');

export const selectUserRole = createSelector(
  stateSelector,
  (state) => state
);


export const selectAccountStatus = createSelector(
  stateSelector,
  (state) => state
);

export const selectAuthUser = createSelector(
  stateSelector,
  (state) => {
    return state;
  }
);
