import {AccountStatus, Levels, UserLevel} from '../../../user/shared/user.models';

export function adminUserLevelActiveHelper(levelType: Levels): boolean {
  const authUser = JSON.parse(sessionStorage.getItem('authUser') || '{}');
  const levelFind = authUser?.levels?.find((x: UserLevel) => x?.name === levelType);

  switch (levelFind?.status) {
    case AccountStatus.ENABLE:
      return true;

    case AccountStatus.DISABLE:
      return false;

    default:
      return false;
  }
}
