import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-form-validation-message',
  templateUrl: './form-validation-message.component.html',
  styleUrls: ['./form-validation-message.component.scss']
})
export class FormValidationMessageComponent implements OnInit {
  @Input()
  control!: AbstractControl | null;

  constructor() { }

  ngOnInit(): void {
  }

}
